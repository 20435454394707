import axios, {
  type AxiosInstance,
  type InternalAxiosRequestConfig,
} from 'axios'

import { clientSideCookieGet } from '../../cookies'
import { type PublicGlobalConfig } from '../../global-config'
import { HTTP_HEADER_X_FEATURE_BRANCH } from '../../http'

/**
 * Interceptor that adds feature branch header to home one api requests.
 */
const homeOneAddFeatureBranchHeader = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const featureBranchCookieValue = clientSideCookieGet(
    HTTP_HEADER_X_FEATURE_BRANCH,
  )

  if (featureBranchCookieValue) {
    config.headers[HTTP_HEADER_X_FEATURE_BRANCH] = featureBranchCookieValue
  }

  return config
}

/**
 * Build axios instance for home one api clients, with default configs.
 * Instance supposed to be reused so do not put any "per request" logic in here.
 */
export const buildHomeOneApiAxiosInstance = (
  publicGlobalConfig: PublicGlobalConfig,
): AxiosInstance => {
  const instance = axios.create()

  if (publicGlobalConfig.deploymentStage === 'reference') {
    instance.interceptors.request.use(homeOneAddFeatureBranchHeader)
  }

  return instance
}
