import { type FC } from 'react'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Skeleton } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { crossSellAccessorGetLocalizedProductDosage } from '../../model/crossSellAccessorGetLocalizedProductDosage'
import { crossSellAccessorGetLocalizedProductUnit } from '../../model/crossSellAccessorGetLocalizedProductUnit'

type CrossSellProductQuantityProps = {
  product?: CrossSellProduct
}

export const CrossSellProductQuantity: FC<CrossSellProductQuantityProps> = ({
  product,
}) => {
  const { language, tenant } = useGlobalConfigContext()

  return (
    <span className="line-clamp-2 min-h-[42px] text-dark-primary-max">
      {product ? (
        [
          `${product.menge} ${crossSellAccessorGetLocalizedProductUnit({
            language,
            product,
            tenant,
          })}`,
          crossSellAccessorGetLocalizedProductDosage({
            language,
            product,
            tenant,
          }),
        ]
          .filter(Boolean)
          .join(' | ')
      ) : (
        <Skeleton className="mx-auto w-1/2" />
      )}
    </span>
  )
}
