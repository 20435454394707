'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type Metadata } from '@redteclab/api/clients/retail-media-xsell-service'

const CrossSellMetadataContext = createContext<Metadata | undefined>(undefined)

export const useCrossSellMetadataContext = (): Metadata | undefined => {
  return useContext(CrossSellMetadataContext)
}

type MetadataProps = PropsWithChildren<{
  metadata?: Metadata
}>

export const CrossSellMetadataProvider: FC<MetadataProps> = ({
  children,
  metadata,
}) => (
  <CrossSellMetadataContext.Provider value={metadata}>
    {children}
  </CrossSellMetadataContext.Provider>
)
