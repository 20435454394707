import { type FC } from 'react'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

type CrossSellProductImageDiscountProps = {
  product: CrossSellProduct
}

export const CrossSellProductImageDiscount: FC<
  CrossSellProductImageDiscountProps
> = ({ product }) => {
  const discount =
    product.reduced === 'true' &&
    `−${product.ersparnis_prozent_text.replaceAll(' ', '')}`

  if (!discount) {
    return null
  }

  return (
    <span className="absolute right-2.5 top-3.5 block aspect-square w-12 rounded-full bg-light-tertiary text-center font-mono font-medium leading-[48px] text-dark-primary-max">
      {discount}
    </span>
  )
}
