'use client'

import { type AxiosError } from 'axios'
import { useCallback } from 'react'
import { type SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { type CrossSellResponse } from '@redteclab/api/clients/retail-media-xsell-service'

import {
  AD_SERVER_USER_ID_COOKIE,
  adServerCookiesGetOrGenerateUserIdentity,
} from '../../../ad-server'
import { buildHomeOneApiAxiosInstance } from '../../../base-api-communication'
import { clientSideCookieGet } from '../../../cookies'
import { useExpaContext } from '../../../expa'
import { useGlobalConfigContext } from '../../../global-config'
import { isServerSide } from '../../../request-context'
import { urlResolverGetCrossSellHorizontalSliderApiEndpoint } from '../../../url-handling'

export type CrossSellHorizontalSliderRequestParams = {
  categoryId?: string
  categoryLabel?: string
  cmUid?: string
  emcid?: string
  listPrice?: string
  productId?: string
  retailPrice?: string
  sellerId?: string
  visibleItemCount?: number
  widgetId: number | string
}

const ECONDA_VISITOR_ID_COOKIE = 'emos_jcvid'

const extractEmvidFromVisitorIdCookie = (
  visitorIdCookie: string | undefined,
): string | null => {
  if (!visitorIdCookie) {
    return null
  }

  const parts = visitorIdCookie.split(':')

  return parts.at(0) ?? null
}

const CROSS_SELL_PDP_CENTER_SLIDER_COM_DE_ID = 297
const CROSS_SELL_HOMEPAGE_TOP_COM_DE_ID = 294
const CROSS_SELL_HOMEPAGE_BOTTOM_COM_DE_ID = 299

export const useSwrCrossSellHorizontalSlider = (
  params?: CrossSellHorizontalSliderRequestParams,
): SWRResponse<CrossSellResponse, AxiosError> => {
  const publicGlobalConfig = useGlobalConfigContext()
  const { language, locale, shopEnv, tenant, tenantAndEnv } = publicGlobalConfig

  const expa = useExpaContext() ?? 'direct'

  let crossSellParams: CrossSellHorizontalSliderRequestParams | null = null

  if (!isServerSide()) {
    crossSellParams = {
      cmUid: params?.cmUid,
      // @ts-expect-error -- need update
      coreShopEnvironment: shopEnv,
      csc: expa,
      csize: 50,
      'ctxcustom.content': expa,
      'ctxcustom.sellerId': params?.sellerId,
      emcid: params?.emcid,
      expa,
      language,
      listPrice: params?.listPrice,
      locale,
      pid: params?.productId,
      retailPrice: params?.retailPrice,
      start: 0,
      tenant,
      tenantPath: tenantAndEnv,
      type: 'cs',
      visibleItemCount: params?.visibleItemCount,
      wid: params?.widgetId,
      widgetdetails: true,
    }
  }

  const fetcher = useCallback(
    async ([url, wid, fetchParams]: [
      url: string,
      wid: CrossSellHorizontalSliderRequestParams['widgetId'],
      fetchParams: CrossSellHorizontalSliderRequestParams,
    ]): Promise<CrossSellResponse> => {
      const emvid = extractEmvidFromVisitorIdCookie(
        clientSideCookieGet(ECONDA_VISITOR_ID_COOKIE),
      )

      /*
       * For this specific widget ID, data needs to be fetched from the Retail Media API.
       * See ticket RMADT-2349 for more details.
       */
      const crossSellWidgetsWithAds = [
        CROSS_SELL_HOMEPAGE_TOP_COM_DE_ID,
        CROSS_SELL_HOMEPAGE_BOTTOM_COM_DE_ID,
        CROSS_SELL_PDP_CENTER_SLIDER_COM_DE_ID,
      ]

      if (crossSellWidgetsWithAds.includes(Number(wid))) {
        const { browserIdConversion, browserIdTargeting } =
          adServerCookiesGetOrGenerateUserIdentity()
        const body = {
          adServerTargeting: window._targeting,
          user: {
            asbidc: browserIdConversion,
            asbidt: browserIdTargeting,
            asuid: clientSideCookieGet(AD_SERVER_USER_ID_COOKIE),
          },
        }

        return buildHomeOneApiAxiosInstance(publicGlobalConfig)
          .post<CrossSellResponse>(url, body, {
            params: { ...fetchParams, emvid },
          })
          .then(({ data }) => data)
      }

      return buildHomeOneApiAxiosInstance(publicGlobalConfig)
        .get<CrossSellResponse>(url, { params: { ...fetchParams, emvid } })
        .then(({ data }) => data)
    },
    [publicGlobalConfig],
  )

  return useSWRImmutable(() => {
    if (!crossSellParams || !params) {
      return null
    }

    const { widgetId } = params

    return [
      urlResolverGetCrossSellHorizontalSliderApiEndpoint(),
      widgetId,
      crossSellParams,
    ]
  }, fetcher)
}
