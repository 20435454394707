import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import {
  type TENANT,
  type TenantLanguage,
  tenantToLocaleRegionMap,
} from '../../tenant'

export const crossSellAccessorGetLocalizedProductDosage = ({
  language,
  product,
  tenant,
}: {
  language: TenantLanguage
  product: CrossSellProduct
  tenant: TENANT
}): string | undefined => {
  return (
    product.darreichungsform ??
    (product[
      // @ts-expect-error update CSW api
      `darreichungsform_${language}_${tenantToLocaleRegionMap[tenant]}`
    ] as string | undefined)
  )
}
