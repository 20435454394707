import { type IntlShape } from '@formatjs/intl'
import { type IntlShape as ReactIntlShape } from 'react-intl'

import { apmCaptureError } from '../apm'
import { priceGetFormattedPrice } from '../price'

const pricePattern =
  /^\*?(?<currency>.+)\s(?<amount>\d+[,.]\d+)(?<superscript>\p{No})?(?:\s\/\s(?<unit>.+))?$/u

/**
 * The function reformats price coming from ECONDA
 *
 * Cross-sell price format:
 * ```
 * ┌─ optional old price asterisk
 * │┌─ currency sign (€) or code (CHF)
 * ││ ┌─ integer
 * ││ │ ┌─ decimal (dot or comma)
 * ││ │ │ ┌─ fraction
 * ││ │ │ │┌─ optional superscript
 * ││ │ │ ││   ┌─ optional unit
 * *€ 10,36¹ / st
 * ```
 */
export const crossSellGetFormattedPrice = (
  value: string,
  intl: IntlShape | ReactIntlShape,
): string | undefined => {
  /**
   * If the field value contains `NA`, it breaks the price pattern.
   * Examples: `€ NA / NA` & `€ NA / NA NA`.
   * This issue should be resolved by https://jira.shop-apotheke.com/browse/WSAWA-5767.
   */
  if (value.includes('NA')) {
    return undefined
  }

  /**
   * Trims the value. In the Belgium tenant, the price may have a leading space, e.g., ` € 7.49`.
   * See: https://jira.shop-apotheke.com/browse/WSAWA-5922
   */
  const match = pricePattern.exec(value.trim())

  if (!match?.groups) {
    apmCaptureError(new Error(`Unable to parse cross-sell price: ${value}`))

    return value
  }

  const currencyCode =
    match.groups.currency === '€' ? 'EUR' : match.groups.currency

  // The price comes already formatted, to be able to parse it replace comma with dot
  const amount = Number.parseFloat(match.groups.amount.replace(',', '.'))

  let formattedPrice = priceGetFormattedPrice(amount, intl, {
    currency: currencyCode,
  })

  if (match.groups.superscript) {
    formattedPrice += match.groups.superscript
  }

  if (match.groups.unit) {
    // Provide correct locale for `st` unit coming from ECONDA
    const unit =
      match.groups.unit === 'st'
        ? intl.formatMessage({ id: 'product.attribute.unit.pcs' })
        : match.groups.unit

    formattedPrice += ` / ${unit}`
  }

  return formattedPrice
}
