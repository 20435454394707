import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { PrescriptionPrescriptionTypeEnum } from '@redteclab/api/clients/bully'
import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Button, Skeleton } from 'base-ui'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { type ProductAddToCartCswCtaParams } from '../../product-page/product-add-to-cart-params/productAddToCartParams'
import { queryStringEncode } from '../../url-handling/queryString'
import { urlResolverGetCartIntermediatePage } from '../../url-handling/urlResolver'
import {
  EXPERIMENTS_TRACK_EVENT_CTA_BUTTON_ON_ALL_CSW,
  experimentsTrackEvent,
} from '../eventTracking'
import { useGetExperiment } from '../hooks'
import { EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW } from '../model/EXPERIMENT_NAME'
import { experimentAccessorNonDefault } from '../model/experimentAccessor'

const useGetCswAddToCartUrl = (product?: CrossSellProduct): string => {
  const globalConfig = useGlobalConfigContext()

  if (!product) {
    return ''
  }

  const queryParams: ProductAddToCartCswCtaParams = {
    cartAction: 'add',
    eventType: 'conversion',
    offer: product.offerId,
    prescriptionType: PrescriptionPrescriptionTypeEnum.NoPrescription,
    product: product.id,
    quantity: 1,
    redirectToCart: false,
  }

  return `${urlResolverGetCartIntermediatePage(
    globalConfig,
  )}${queryStringEncode(queryParams)}`
}

export const ExperimentCtaButtonOnAllCsw: FC<{
  product?: CrossSellProduct
}> = ({ product }) => {
  const experimentCtaButtonOnAllCsw = useGetExperiment(
    EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  )
  const config = useGlobalConfigContext()
  const addToCartCswUrl = useGetCswAddToCartUrl(product)

  if (!experimentAccessorNonDefault(experimentCtaButtonOnAllCsw)) {
    return null
  }

  if (!product) {
    return (
      <div className="flex flex-col items-center">
        <Skeleton className="h-9 w-full" />
      </div>
    )
  }

  return (
    <Button<'a'>
      className="w-full"
      data-qa-id="add-to-cart-button"
      href={addToCartCswUrl}
      onClick={() => {
        experimentsTrackEvent(
          config,
          EXPERIMENTS_TRACK_EVENT_CTA_BUTTON_ON_ALL_CSW,
        )
      }}
      size="small"
      slots={{ root: 'a' }}
      variant="outlined"
    >
      <FormattedMessage id="general.button.label.addToCart" />
    </Button>
  )
}
